.social-icons
{
    text-align: right;
}
.social-icons
{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.social-icons li
{
    display: inline-block;
    margin-bottom: 4px;
}
.social-icons li.title
{
    margin-right: 15px;
    text-transform: uppercase;
    color: #96a2b2;
    font-weight: 700;
    font-size: 13px;
}
.social-icons a
{
    background-color: #3a3b3c;
    color: #818a91;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}
.social-icons a:active, .social-icons a:focus, .social-icons a:hover
{
    color: #fff;
    background-color: #29aafe;
}
.social-icons.size-sm a
{
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px;
}
.social-icons a.instagram:hover
{
    background-color:  #d6249f;
}
.social-icons a.github:hover
{
    background-color: #f5f5f5;
}
.social-icons a.linkedin:hover
{
    background-color: #007bb6;
}
.social-icons a.spotify:hover
{
    background-color: #1DB954;
}
.social-icons a.gitlab:hover
{
    background-color: #FC6D27;
}
@media (max-width: 767px)
{
    .social-icons
    {
        padding-top: 15px;
        text-align: center;
    }
    .social-icons li
    {
        margin-right: 0;
    }

    p
    {
        padding-bottom: 5px;
    }
}
